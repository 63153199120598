var MantleCarousel = MantleCarousel || {};
var MantlePerf = MantlePerf || {};
var MantleMorph = MantleMorph || {};

(function ($, prodcat, site) {
  /*
   * Managers SPP Image Carousel
   */
  var ProductFullImageManager = function ($imageContainer) {
    this.$imageContainer = $imageContainer;

    this.breakpoint = Unison.fetch.now();
    this.lastIndex = 0;
    this.setDom();

    this.$imageContainer.data('product-full-image-manager', this);
  };

  // Since we dynamically render. we need to reget dom refs
  ProductFullImageManager.prototype.setDom = function () {
    var $imageContainer = this.$imageContainer;
    this.$sppImages = $('.js-product-full__image-carousel', $imageContainer);
    this.$carousel = $('.js-spp-carousel', this.$sppImages);
    this.$thumbnailCarousel = $('.js-product-full__image-thumbnails', $imageContainer);
    this.$carousel.addClass('center-mode-carousel');
  };

  ProductFullImageManager.prototype.init = function () {
    var self = this;
    var $imageContainer = this.$imageContainer;
    var targetSku = $imageContainer.attr('data-target-sku-base-id');

    this.handleSkuSelect(targetSku);
    this.reinit();

    // This only needs to be set once.
    Unison.on('change', function (breakpoint) {
      self.breakpoint = breakpoint;
      self.setZoomTemplate(self.$carousel, breakpoint);
      self.reinitSlick(self.$carousel, self.$thumbnailCarousel);
    });
  };

  ProductFullImageManager.prototype.reinit = function () {
    var self = this;
    var $carousel = this.$carousel;
    var $thumbnailCarousel = this.$thumbnailCarousel;
    var $imageContainer = this.$imageContainer;
    // instant on is optional. This will load iamges if instant-on isn't setup
    var $newImages = $('img:not(.instant-on-processed):not(.no-lazyload)', this.$imageContainer);

    $newImages.addClass('lazyload');

    this.setEvents($carousel);

    /*
     * setTimeout to let images and such to settle down.
     *
     * We should not be relying on slickcarousel to show the images.
     *
     * TODO: init carousel after thumbs + new active image is loaded instead of
     * timeout.
     */
    window.setTimeout(function () {
      MantlePerf.thresholdLog(function () {
        self.initDefaultCarousel($carousel, $imageContainer, $thumbnailCarousel);
        self.setZoomTemplate($carousel, self.breakpoint);
        self.reinitSlick(self.$carousel, self.$thumbnailCarousel);
      }, 'ProductImageFull.reinit:carousels');
    }, 300);
  };

  ProductFullImageManager.prototype.setEvents = function ($carousel) {
    var self = this;

    self.setCarouselBehavior($carousel);
  };

  /*
   * Essentially the sku that the dom is currently tied to.
   */
  ProductFullImageManager.prototype.getCurrentSkuBaseId = function () {
    var currentSku = this.$imageContainer.attr('data-sku-base-id');

    if (currentSku) {
      currentSku = parseInt(currentSku);
    }

    return currentSku;
  };

  ProductFullImageManager.prototype.setCurrentSkuBaseId = function (skuBaseId) {
    this.$imageContainer.attr('data-sku-base-id', skuBaseId);
  };

  /*
   * Handles the setup/fixing of a slide by index.
   *
   * Right now this handles lazy loading zoom and fixing lazyload errors in
   * cloned slides.
   */
  ProductFullImageManager.prototype.setupSlideByIndex = function ($carousel, index, slick) {
    var targetSlidePack = MantleCarousel.get_slidepack_by_index($carousel, index, slick);
    var $targetSlide = $(targetSlidePack.slide);
    var $allSlides = $(targetSlidePack.allSlides);

    if (this.breakpoint.name === 'landscape') {
      $targetSlide.trigger('zoom.destroy');
      if ($targetSlide.hasClass('.js-product-zoom')) {
        $targetSlide.zoom({
          magnify: 0.8
        });
      }
    }

    // eslint-disable-next-line no-undef
    _mantle_carousel_check_for_lazy_errors($allSlides);
  };

  ProductFullImageManager.prototype.setCarouselBehavior = function ($carousel) {
    var self = this;

    $carousel.off('.ProductFullImageManager');
    /*
     * While slide carousle. attach zoom to target slide so it's ready.
     */
    $carousel.on(
      'beforeChange.ProductFullImageManager',
      function (e, slick, currentSlide, targetSlide) {
        self.setupSlideByIndex($carousel, targetSlide, slick);

        // Pause video when shifting carousel image (ASMBLY8-357)
        if (self.hasVideo()) {
          $(document).trigger('videos.pause');
        }
      }
    );

    /*
     * Since before/afterChange doesn't get triggered for first init. We need
     * to fix the initial slide here.
     */
    $carousel.on('init.productFullImageManager', function (e, slick) {
      // Note that MantleCarousel.currentSlide() doesn't work here because init
      // is caled *before* slick gets added to its internal regsitry.
      var currentSlide = slick.getCurrent();

      self.setupSlideByIndex($carousel, currentSlide, slick);
    });
  };

  ProductFullImageManager.prototype.initDefaultCarousel = function (
    $carousel,
    $container,
    $thumbnailCarousel
  ) {
    var arrowsDiv = $('.carousel-controls', $container);
    var dotsDiv = $('.carousel-dots', $container);
    var sppSlide = '.js-spp-carousel__slide';
    // case for custom slides count and dots for mobile
    var settings = {
      slide: sppSlide,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      initialSlide: this.lastIndex,
      appendArrows: arrowsDiv,
      appendDots: dotsDiv,
      rows: 0,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '12vw'
          }
        }
      ]
    };
    var thumbnailSettings = {
      arrows: true,
      vertical: true,
      slide: '.js-spp-carousel__thumbnail',
      slidesToShow: 4,
      slidesToScroll: 1,
      focusOnSelect: true,
      rows: 0
    };

    if ($thumbnailCarousel.is(':visible')) {
      settings.asNavFor = $thumbnailCarousel;
      thumbnailSettings.asNavFor = $carousel;
    }

    if (this.lastIndex) {
      if ($thumbnailCarousel.is(':visible')) {
        settings.initialSlide = this.lastIndex;
        thumbnailSettings.initialSlide = this.lastIndex;
        this.lastIndex = 0;
      }
    }

    // Init this carousel with our settings
    if (!MantleCarousel.isCarousel($carousel)) {
      MantleCarousel.initializeCarousel($carousel, settings);
    }
    if (!MantleCarousel.isCarousel($thumbnailCarousel)) {
      MantleCarousel.initializeCarousel($thumbnailCarousel, thumbnailSettings);
    }
  };

  ProductFullImageManager.prototype.handleSkuSelect = function (skuBaseId) {
    var $imageContainer = this.$imageContainer;

    if (!$imageContainer.length) {
      return;
    }

    if (!skuBaseId) {
      return;
    }

    skuBaseId = parseInt(skuBaseId);

    // check against current sku | avoid rendering twice
    var currentSku = this.getCurrentSkuBaseId();

    if (currentSku) {
      currentSku = parseInt(currentSku);
    }

    if (skuBaseId === currentSku) {
      return;
    }

    // container keeps track of its current sku and not $product
    $(() => {
      this.setCurrentSkuBaseId(skuBaseId);
      this.renderImagesForSku(skuBaseId);
    });
  };

  ProductFullImageManager.prototype.isMobile = function () {
    var isMobile = $(window).width() < 640;

    return isMobile;
  };

  ProductFullImageManager.prototype.hasVideo = function () {
    var $videoContainer = $('.js-spp-carousel__slide .js-product-video', this.$imageContainer);

    return $videoContainer.length > 0;
  };

  ProductFullImageManager.prototype.attachVideoVariables = function (data) {
    var $video = $('.js-spp-carousel__slide .js-product-video', this.$imageContainer);
    var video_image = $('.js-video_image', $video).attr('data-src');
    var yt = !!$video.attr('data-video-provider');
    var ytId = $video.attr('data-youtube-id');
    // additional support will be needed for html5 and zentrick
    // provider-htmlfive
    // provider-zentrick
    // id
    // html

    data = _.merge(data, {
      video_image: {
        src: video_image
      },
      video: {
        video_image: {
          src: video_image
        },
        'provider-youtube': yt,
        youtube_id: ytId
      }
    });

    return data;
  };

  /*
   * Generate the HTML of the product_image_full for a sku.
   */
  // eslint-disable-next-line complexity
  ProductFullImageManager.prototype.generateImagesForSku = function (skuBaseId) {
    var $imageContainer = this.$imageContainer;
    var isMobile = this.isMobile();
    var old_height = $imageContainer.height();

    if (isMobile && old_height > 0 && $imageContainer.find('.slick-initialized').length > 0) {
      // keep image from going up and down.
      $imageContainer.css('min-height', old_height);
    }

    var productId = $imageContainer.data('product-id');
    var prod = productId ? prodcat.data.getProduct(productId) : null;
    var sku = prodcat.data.getSku(skuBaseId);
    // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    var data = sku ? sku : prod.defaultSku ? prod.defaultSku : prod;
    var thumbnailImagePack = prodcat.ui.generateLargeImagePackWithWidth(data, 150);

    if (thumbnailImagePack) {
      data.thumbnail_image_pack = thumbnailImagePack;
    }

    // check for video
    if (this.hasVideo()) {
      data = this.attachVideoVariables(data);
    }

    /* ------- Sku Images ------- */
    var productImageFullHtml = site.template.get({
      name: 'product_image_full',
      data: data
    });

    return productImageFullHtml;
  };

  /*
   * From the productImageFullHtml we turnit into dom and process.
   *
   * Parts of this *could* be handedl via the Mustache template. However that
   * would require changing the schema of the data.
   *
   * Also note that the HTML generated will not load any images. This is due
   * to the fact that we don't want to render the wrong sku images. We don't
   * know what sku we are on server side due to shade routing.
   */
  ProductFullImageManager.prototype.processProductImageFullHtml = function (
    productImageFullHtml,
    initialSlide,
    thumbsToShow
  ) {
    // Create dom from HTML and then apply optimizations.

    // By default this template's mustache does not load images. This is
    // because we need to control the image load behavior since we don't want
    // to load the default sku's image if the shade routing is for a different
    // sku. We trigger lazyload in the ajax render.
    //
    // The initial pageload images are handled via
    // MantleInstantOn._renderSPPImages
    var $newProductImageFull = $(productImageFullHtml);
    var $newMainCarousel = $('.js-spp-carousel', $newProductImageFull);
    var $newImages = $('img', $newProductImageFull);
    var $newMainSlides = $('.js-spp-carousel__slide', $newProductImageFull);
    var $newThumbSlides = $('.js-spp-carousel__thumbnail', $newProductImageFull);

    // Default to newImages being lazyloaded.
    $newImages.addClass('lazyload');
    $newMainCarousel.addClass('fe-rendered');

    // initial-current-slide is used to trigger the main slide quickly when
    // it's not jsut the first default slide.
    $newMainSlides.removeClass('initial-current-slide');
    var $activeNewSlide = $($newMainSlides.get(initialSlide));

    $activeNewSlide.addClass('initial-current-slide');
    var $activeNewImage = $('img.lazyload', $activeNewSlide);
    var lazySource = $activeNewImage.data('src');

    if (lazySource) {
      $activeNewImage.removeClass('lazyload');
      $activeNewImage.addClass('no-lazyload');
      $activeNewImage.attr('src', lazySource);
    }

    // Adjust the thumbs to not lazyload the visible thumbs.
    // Note that noLazyEndIndex is inclusive.
    var noLazyEndIndex = initialSlide + thumbsToShow - 1;
    // Need to adjust for wraparound infinite. If no wrap then wraparoundCutoff
    // is negative and never gets matched below.
    var wraparoundCutoff = noLazyEndIndex - ($newThumbSlides.length - 1);

    $newThumbSlides.each(function (i, obj) {
      if ((i >= initialSlide && i <= noLazyEndIndex) || i < wraparoundCutoff) {
        var $thumbImage = $('img.lazyload', obj);

        if ($thumbImage.data('src')) {
          $thumbImage.attr('src', $thumbImage.data('src'));
          $thumbImage.removeClass('lazyload');
          $thumbImage.addClass('no-lazyload');
        }
      }
    });

    return $newProductImageFull;
  };

  /*
   * Use MantleMorph to change out a carousel without destroy/recreating it. We
   * try to minimize any dom mutations.
   *
   * TODO: This function sort of generalizes. It should be moved out as
   * handling mutating carousels is its own special messiness.
   */
  ProductFullImageManager.prototype.applyNewDomToCarousel = function ($carousel, $newSlides) {
    var timeToInit = 50;
    var newSlideCount = $newSlides.length;
    // First thing we do is adjust the # of slides in original to match up with
    // new slides. Doing this first keeps the dom jitter low.
    if (!MantleCarousel.isCarousel($carousel)) {
      setTimeout(() => {
        this.applyNewDomToCarousel($carousel, $newSlides);
      }, timeToInit);
      return;
    }
    var currentSlideCount = MantleCarousel.slideCount($carousel);
    var slideOverflow = newSlideCount - currentSlideCount;
    var slidesToRemove = slideOverflow < 0 ? Math.abs(slideOverflow) : 0;

    // Remove slides from the end when needed.
    for (var i = 0; i < slidesToRemove; i++) {
      MantleCarousel.removeSlide($carousel, -1);
    }

    // Add the missing slides to the end of the existing carousel. Note we add
    // them in order so that the Morphing will have no diffs.
    for (var addIndex = currentSlideCount; addIndex < newSlideCount; addIndex++) {
      var overflowSlide = $newSlides.get(addIndex);

      MantleCarousel.addSlide($carousel, overflowSlide);
    }

    var morphOptions = {
      // empty for now.
    };

    $newSlides.each(function (i, newSlide) {
      var slidePack = MantleCarousel.get_slidepack_by_index($carousel, i);
      var allSlidesCount = slidePack.allSlides.length;
      var hasClones = allSlidesCount > 1;
      var targetNewSlide = newSlide;

      // We iterate of allSlides to also take care of clones.
      $.each(slidePack.allSlides, function (o_index, original) {
        targetNewSlide = newSlide;
        // If we have slick clones, we need to also clone the target dom.
        // We make sure to not clone more than we need to. The last iteration
        // should always use the passed in newSlide.
        if (hasClones && o_index < allSlidesCount - 1) {
          targetNewSlide = newSlide.cloneNode(true);
        }
        MantleMorph.morphChildren(original, targetNewSlide, morphOptions);
      });
    });
  };

  ProductFullImageManager.prototype.applyNewDom = function ($newProductImageFull) {
    var $carousel = this.$carousel;
    var $thumbnailCarousel = this.$thumbnailCarousel;
    var $newMainSlides = $('.js-spp-carousel__slide', $newProductImageFull);
    var $newThumbSlides = $('.js-spp-carousel__thumbnail', $newProductImageFull);

    this.applyNewDomToCarousel($carousel, $newMainSlides);
    this.applyNewDomToCarousel($thumbnailCarousel, $newThumbSlides);
  };

  /*
   * Attach product_image_full html to dom and initializie.
   */
  ProductFullImageManager.prototype.renderImagesForSku = function (skuBaseId) {
    var $imageContainer = this.$imageContainer;
    var $activeSliders = $imageContainer.find('.slick-initialized');

    if ($activeSliders.length) {
      this.lastIndex = $activeSliders.slick('slickCurrentSlide');
    }

    var productImageFullHtml = this.generateImagesForSku(skuBaseId);
    var thumbsToShow = 4;
    var $newProductImageFull = this.processProductImageFullHtml(
      productImageFullHtml,
      this.lastIndex,
      thumbsToShow
    );

    this.applyNewDom($newProductImageFull);

    // Refresh the slick slider if there is one
    // self.reinit();
    // $(document).trigger('productflag.init', $imageContainer);
  };

  ProductFullImageManager.prototype.reinitSlick = function ($carousel, $thumbnailCarousel) {
    $carousel.slick('slickFilter', ':not(.hidden)');
    $carousel.slick('setPosition');
    $thumbnailCarousel.slick('slickFilter', ':not(.hidden)');
    $thumbnailCarousel.slick('setPosition');
  };

  ProductFullImageManager.prototype.setZoomTemplate = function ($carousel, breakpoint) {
    var $zoomImages = $('.js-spp-carousel__slide', $carousel);
    var $slickTrack = $('.slick-track', $carousel);

    switch (breakpoint.name) {
      case 'small': {
        $zoomImages.trigger('zoom.destroy');
        $('.js-zoom')
          .once()
          .on('click', function () {
            // make the image full screen add a fixed x
            var zoomImage = $('.slick-active', $slickTrack).html();
            var $this = $(this);

            if ($this.hasClass('zoomed')) {
              $('body > .zoomed-image').remove();
            } else {
              var $imageModal = $();

              $('body')
                .once('desktop-zoom')
                .append(
                  '<div class="product-full__img-zoom-modal js-product-full__img-zoom-modal"></div>'
                );
              $imageModal = $('.js-product-full__img-zoom-modal', $carousel);
              $imageModal.append(zoomImage);
              $imageModal.append(
                '<button class="js-close-zoom close"><span class="visuallyhidden">Close</span></button>'
              );
              $('.js-close-zoom')
                .once()
                .on('click', function () {
                  $imageModal.remove();
                  $this.removeClass('zoomed');
                });
            }
          });
        break;
      }
      case 'medium': {
        $zoomImages.trigger('zoom.destroy');
        break;
      }
      case 'landscape': {
        $('.site-container').css('overflow-x', 'visible');
        // Only call this here when there is only one image.
        // Otherwise we want to trigger zoom on demand.
        if (!MantleCarousel.isCarousel($carousel)) {
          $zoomImages.zoom({
            magnify: 0.8
          });
        }
      }
    }
  };

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);
    var $containers = $('.js-product-full__image', $product);

    $containers.each(function () {
      var $container = $(this);
      var productFullImageManager = $container.data('product-full-image-manager');

      if (productFullImageManager) {
        MantlePerf.thresholdLog(function () {
          productFullImageManager.handleSkuSelect(skuBaseId);
        }, 'ProductImageFull.handleSelect');
      } else {
        // we haven't inited the carousel yet. Store the new sku base id
        $container.attr('data-target-sku-base-id', skuBaseId);
      }
    });
  });

  Drupal.behaviors.productFullImageV1 = {
    attach: function (context) {
      // images
      var $containers = $('.js-product-full__image', context);

      $containers.each(function () {
        var $container = $(this);
        var productFullImageManager = new ProductFullImageManager($container);

        productFullImageManager.init();
      });
    }
  };
})(jQuery, window.prodcat || {}, window.site || {});
